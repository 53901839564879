import React from 'react';
import Slider from '../components/slider';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/wallet-hero.png';
import AboutMingo from '../features/about/ecosystem';
import AboutHedera from '../features/about/about-hedera';

//images
import image1 from '../assets/images/common/wallet-about.png';
import { Link } from 'react-router-dom';

const HeroSectionData = [
    {
        id: 1,
        superTitle: 'Welcome To',
        title: <h1 className="title">MINGO WALLET</h1>,
        // desc : <p className="sub-title">The marketplace is the go to for any purchases within the Mingo Ecosystem. Users can purchase NFTs directly using their credit card or by using MINGO and can list NFTs for sale on the secondary market.</p>,
        desc: <p className='sub-title'>
            Store Hedera's native cryptocurrency, HBAR, and all your Hedera HTS tokens, including MINGO, in one place. This also includes NFT’s. Now you can view, send & swap Hedera NFTs with ease.</p>,
        img : image,
        button: false,
        btnText: 'INSTALL THE MINGO APP',
        p: 182,
        link: '/install'
    },
]

const data = {
    title: 'STORE ALL HTS TOKENS & NFTS',
    desc: "MINGO Wallet offers streamlined access to the Hedera network’s capabilities, enabling you to manage cryptocurrencies, NFTs, and digital tickets efficiently. Designed for ease of use and robust security, our wallet facilitates smooth transactions and comprehensive asset management in one intuitive interface. Engage with the digital economy securely and straightforwardly with MINGO Wallet.",
    superTitle: 'Mingo Wallet',
    image: image1,
    button: true,
    link: '/install'
}


function MingoWallet(props) {
    const [openInstall, setOpenInstall] = React.useState(false);

    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />
            {/* <section className="tf-section faq">
            <div className={`'container'`}>
                <div className="row">
                    <div className="col-md-12">
                        <div className={`wrap-fx justify-space-around 'flex-row-reverse'`} >
                            <div className="image-faq" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1200">
                                <img src={data.image} alt="" />
                            </div>

                            <div className="content-faq">
                                <div className="tf-title st2 m-b50" data-aos="fade-up" data-aos-duration="800">
                                    <p className="h8 sub-title">{data.superTitle}</p>
                                    <h4 className="title">{data.title}</h4>
                                    <p className="">{data.desc}</p>
                                    {data.button && <div className="wrap-btn">
                                        <Link onClick={() => setOpenInstall(true)} to={`${data.link ? data.link : '/'}`} className="tf-button-st2 btn-effect"><span className="effect">
                                            {!data.btnText ? 'Install App' : data.btnText}
                                            <i className="fal fa-arrow-down"></i></span>
                                        </Link>
                                    </div>}
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>         */}
            <AboutHedera data={data} />
        </div>
    );
}

export default MingoWallet;