import React from 'react';
import logo from '../../assets/images/icons/logo.svg';
import { QRCodeCanvas } from 'qrcode.react';
import qrBackground from '../../assets/images/icons/qr-background.svg';
import './styles.scss';

const InstallOnlyOnMobile = () => {
  return (
    <div className=" mt-5 container d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div className="text-center mb-5">
        <h4 className="fw-bold">Oh hey!</h4>
        <p className="text-muted">
          Sorry, this experience is designed for mobile only. Please scan the QR code to continue on your smart phone.
        </p>
      </div>
      <div className="position-relative d-flex justify-content-center">
        <div className="qr-code-container bg-white p-4 rounded">
          <QRCodeCanvas value={process.env.REACT_APP_MINGO_APP_INSTALL_URL || 'https://mingo.com/install'}  className="w-100 h-100 z-100" />
        </div>
        <img src={qrBackground} alt="MINGO WALLET" className="position-absolute top-0 start-0 w-100 h-100" />
      </div>
      <div className=""></div>
      <div className="text-center">
        <img src={logo} alt="MINGO" className="mb-3" />
        <p className="text-muted">
          Copyright &copy; 2024 Mingo Technologies Ltd Gibraltar. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default InstallOnlyOnMobile;
