import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss' ;
// import { Modal } from "react-bootstrap";
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import bgImg from '../../../assets/images/background/Hero Section.png';

EventSliderItem.propTypes = {
    item : PropTypes.object,
};

function EventSliderItem({ item }) {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)

    // const [modalShow, setModalShow] = useState(false);

    return (
        <div className={`box-slider ${item.classAction}`}>
            <img className='bg-slider' src={item.image} alt="mingo" />
            <div className="slider-overlay"></div>
            <div className="box-slider__main "
            // style={{
            //     backgroundImage: `url(${item.image})`,
            //     backgroundSize: 'cover',
            //     backgroundPosition: 'center',
            //     backgroundRepeat: 'no-repeat'
            // }}
            >
                <div className="container bg-transparent">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className={`content-box ${item.p == 182 && 'pb-182'}`} >
                                <p className="h8">{item.superTitle}</p>
                                {item.title}
                                {item.desc}
                                <div className="wrap-btn">
                                {item.button && <Link to="/install" className="tf-button-st2 btn-effect"><span className="effect">Install App <i className="fal fa-arrow-down"></i></span></Link>}
                                    <button className="tf-button btn-effect" onClick={() => navigate('/wallet')}>
                                    
                                        <span className="boder-fade"></span>                                     
                                        <span className="effect">{item.btnText? item.btnText :'View Features'}</span>
                                    </button>
                                    
                                </div>
                            </div> */}
                            <div className="content-box">
                                <p className="h8">{item.superTitle}</p>
                                <h2>{item.title}</h2>
                                <p>
                                    <i className="fas fa-map-marker-alt"></i> &nbsp;
                                    {item.location} &nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* display time */}
                                    <span className='special'>
                                    <i className="far fa-clock"></i> &nbsp;
                                    {item.scheduledAt}
                                    </span> <br />
                                    </p>
                                    {/* display date */}
                                    
                                <div className="wrap-btn">
                                    <button className="tf-button btn-effect" onClick={() => setOpen(true)}>
                                        <span className="boder-fade"></span>
                                        <span>Install Now to Buy</span>
                                        {/* <span className="effect">{item.btnText? item.btnText :'View Features'}</span> */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="i7EMACWuErA" onClose={() => setOpen(false)} />

            {/* <Modal
                show={modalShow}
                onHide={setModalShow}
            >
            <Modal.Header closeButton></Modal.Header>

                <div className="modal-body center">
                                <div className="box-wallet-inner">
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-1.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Meta Mask</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-6.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#"> Bitski</a></h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/vector.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Fortmatic</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-2.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Coinbase Wallet</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-3.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Authereum</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                    <div className="sc-box">
                                        <div className="img">
                                            <img src="assets/images/common/icon-4.png" alt="Crybox" />
                                        </div>
                                        <h6 className="heading"><a href="#">Kaikas</a> </h6>
                                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </div>
                                </div>  
                            </div>
            </Modal> */}

            {/* <div className="modal fade popup" id="popup_bid" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="header-popup">
                        <h5>Connect to a wallet</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    
                </div>
            </div>
        </div> */}
        </div>
    );
}

export default EventSliderItem;