import lock from "../images/common/lock.png";
import door from "../images/common/door.png";
import nft from "../images/common/nft.png";
import mingo from "../images/common/mingo.png";
import mobile from "../images/common/mobile.png";

export const dataOffers = [
    {
        id: 1,
        title: 'Safe and Decentralized',
        img: lock,
        desc: 'MingoWallet is a self-custodial wallet, giving you complete control of your crypto. All wallet information is kept securely on your devices.',
    },
    {
        id: 2,
        title: 'Seamless Onboarding and User-Friendly Experience',
        img: door,
        desc: 'Begin your journey into the world of cryptocurrencies effortlessly, gaining access to Web3 functionalities with just a few simple taps.'
    },
    {
        id: 3,
        title: 'NFT Marketplace within the Ecosystem',
        img: nft,
        desc: 'Easily manage, store, and seamlessly view all your Mingo NFTs directly within your wallet. Discover, explore, and purchase new ones through the integrated MingoMarket.',
    },
    {
        id: 4,
        title: 'Instant Swap',
        img: mingo,
        desc: "Easily swap Mingo tokens at your convenience, whether you're looking to diversify your holdings, participate in tokenomics, or explore new opportunities within the Mingo ecosystem. Our intuitive platform ensures smooth transactions and real-time updates, empowering you to manage your digital assets with confidence."
    },
    {
        id: 5,
        title: 'Use dApps on any device',
        img: mobile,
        desc: 'Use any dApp on the Mingo ecosystem with our state-of-the-art Wallet Connect support. Discover new decentralized applications and use them without leaving your wallet.'
    }
]