import React from 'react';
import Slider from '../components/slider';
import dataWork, { mingoTickets } from '../assets/fake-data/data-work';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/hero-image.png';
import AboutMingo from '../features/about/ecosystem';
import AboutHedera from '../features/about/about-hedera';
import mingoTicketImg from '../assets/images/common/mingoticket-about.png';

//images
import image1 from '../assets/images/common/about-illustration.png';
import TicketAbout from '../features/tickets/about/TicketAbout';
import fightCircus12 from '../assets/images/post/fight-circus.jpeg'
import cagedSteel from '../assets/images/post/caged-steel.png';
import EventSlider from '../components/slider/EventSlider';

const HeroSectionData = [
    {
        id: 1,
        superTitle: 'Welcome To',
        title: <h1 className="title">MINGO TICKETS</h1>,
        desc : <p className="sub-title">MINGO Tickets leverages blockchain technology to issue secure, reliable tickets, eliminating outrageous secondary market pricing and fraud. Our digital tickets offer a variety of innovative benefits for both fans and organizers, providing features unmatched by traditional ticketing services. <br />
        <span className='special'>Smart, Programmable, Digital Tickets. </span ><br /> Get the MINGO Wallet Now
        </p>,
        img : image,
        button: true
    },
]

const dataAbout = {
    title: 'Powered By Hedera',
    superTitle: 'Our Partner',
    desc: "A future built on trust. Hedera is the most used enterprise-grade public network for you to make your digital world exactly as it should be – yours. We're delighted to be working with Hedera to bring a platform built just for you.",
    button: true,
    image: image1,
}

const dataAbout2 = {
    title: 'The story behind MingoApp',
    superTitle: 'Our Story',
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.",
    button: false,
    image: image1,
}

const events = [
    {
        "eventId": "941ec0c6-9a76-11ef-8c76-d3e9bdbbee95",
        "title": "Caged Steel - Night of Champions",
        "image": cagedSteel,
        "imageCidr": "bafybeifpdmzecnevwdprlcb5ddanrcudkvervfbzzvxmmbmqml3proijmq",
        "location": "DONCASTER DOME",
        "locationImageCidr": "bafkreia4xl7fjv6we6i4opmhhufegky4c4fne6gztsbhvrwr3z4m5rvcki",
        "scheduledAt": "2024-12-07",
        
    },
    {
        "eventId": "fe80a2e4-9f7c-11ef-a281-93bf0bdd45df",
        "title": "Fight Circus 12",
        "image": fightCircus12,
        "imageCidr": "bafkreie5qgss3hz3udgwkqkuvlad3ycn7wreayn5gbaey2v2csumnh33ly",
        "location": "Junk Yard Theatre",
        "locationImageCidr": "bafkreigxaayu5i6fraihmyrx5rwe4jxzn62t22mgzi7ats7pyw5jpojjvi",
        "scheduledAt": "2024-11-17",
    }
]
function MingoTickets(props) {
    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />
            <TicketAbout data={mingoTickets} />
            <EventSlider data={events} />
            {/* <section className="tf-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title  t-left" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">Mingo Play</p>
                            <h4 className="title">Upcoming Release</h4>
                            <p className="">There are 2 planned releases for 2024. The first play to earn games on the Hedera network! <br /> The combination of lightning-fast, low-cost transactions, and a DDOS-resistant network made Hedera an excellent fit for blockchain gaming. <br /> We believe the real future of play to earn gaming shouldn't be a grind. That's why everything we develop is centered around our fun first approach. If you wouldn't play for fun you shouldn't play to earn. </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <img src={mingoTicketImg} alt="Meta Fighting championship" />
                    </div>
                    </div>
            </div>
        </section> */}

        </div>
    );
}

export default MingoTickets;