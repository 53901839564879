import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

OfferItem.propTypes = {
    item: PropTypes.object,
};

function OfferItem({ item }) {


    return (
        <div className={`offer-box '}`}>
            <h6 className='title' to="/nft-item">{item.title}</h6>
            <img src={item.img} alt="crybox" />
            <p className='desc'>{item.desc}</p>
            <div className="content">
            </div>
        </div>
    );
}

export default OfferItem;