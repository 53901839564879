import img1 from '../images/common/joe.png';
import img2 from '../images/common/team7.png';
import img3 from '../images/common/team8.png';
import img4 from '../images/common/team9.png';
import img5 from '../images/common/team10.png';
import img6 from '../images/common/team11.png';
import tej from '../images/post/jason-teji.jpg';
import naima from '../images/post/naima.jpg';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Joe Arthur',
        position: 'Founder & CEO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Deirdre Arthur',
        position: 'Co-Founder & President',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 3,
        img: tej,
        name: 'Jason Teji',
        position: 'COO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Stefan Teubes ',
        position: 'CTO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Abraham Omadoye',
        position: 'Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 3,
        img: naima,
        name: 'Naima ',
        position: 'Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/naimaghm/'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/naima.ghm/'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: 'https://t.me/naimaghm/'
            },
            {
                id: 4,
                icon: 'fab fa-discord',
                link: 'https://discord.com/users/789856282059866182'
            },
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Alan Shortt',
        position: 'Communication Advisor',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'Cillian Arthur',
        position: 'Lead Development Team',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    // {
    //     id: 6,
    //     img: img6,
    //     name: 'Alannah Arthur',
    //     position: 'Lead Marketing Team',
    //     listsocial: [
    //         {
    //             id: 1,
    //             icon: 'fab fa-twitter',
    //             link: '#'
    //         },
    //         {
    //             id: 2,
    //             icon: 'fab fa-facebook-f',
    //             link: '#'
    //         },
    //         {
    //             id: 3,
    //             icon: 'fa fa-paper-plane',
    //             link: '#'
    //         },
    //         {
    //             id: 4,
    //             icon: 'fab fa-reddit',
    //             link: '#'
    //         },
    //     ]
    // },
    {
        id: 7,
        img: img2,
        name: 'Hassans International Law',
        position: 'Appointed Legal Counsel',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 8,
        img: img2,
        name: 'David Cohen',
        position: 'Technology & Crypto Advisor',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
]

export default dataTeam;