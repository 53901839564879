import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import mingoPlay from "../../../assets/images/common/about-hedera.png";
import AboutMingoItem from '../../about/ecosystem/aboutMingoItem';
import '../../about/ecosystem/aboutMingoItem/styles.scss';

TicketAbout.propTypes = {
    data: PropTypes.array,
};

function TicketAbout(props) {

    const {data} = props;

    return (
        <section className="tf-section mingo-about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h4>
                        MINGO TICKETING IS HERE!
                        </h4>
                        <p className='mx-10'>
                         <br />
                        </p>
                    </div>
                    {
                        data.map(item => (
                            <div key={item.id} className="col-md-6 p-0" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <AboutMingoItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default TicketAbout;