import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';
import { Link } from 'react-router-dom';


AboutHedera.propTypes = {
    data: PropTypes.array,
};

function AboutHedera({ reverse, data, fullWidth }) {


    const [dataBlock] = useState({
        subtitle: 'FAQs',
        title: 'Looking for answers?'
    })

    return (
        <section className="tf-section faq">
            <div className={`${fullWidth ? 'container-fluid': 'container'}`}>
                <div className="row">
                    <div className="col-md-12">
                        <div className={`wrap-fx justify-space-around ${reverse && 'flex-row-reverse'}`} >
                            <div className="image-faq" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1200">
                                <img src={data.image} alt="Mingo"/>
                            </div>

                            <div className="content-faq">
                            <div className="tf-title st2 m-b50" data-aos="fade-up" data-aos-duration="800">
                                <p className="h8 sub-title">{data.superTitle}</p>
                                <h4 className="title">{data.title}</h4>
                                <p className="">{data.desc}</p>
                                {data.button && <div className="wrap-btn">
                                    <Link to={`${data.link ? data.link : '/'}`} className="tf-button-st2 btn-effect"><span className="effect">
                                        {!data.btnText ? 'Install App' : data.btnText} <i className="fal fa-arrow-down"></i></span>
                                    </Link>
                                </div>}
                            </div>
                            
                            
                        </div>
                        </div>
                        
                    </div>
                
                </div>
            </div>
        </section>
    );
}

export default AboutHedera;