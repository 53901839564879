import React from 'react';
import icon from "../../assets/images/icons/mingo-icon.png";
import safariShare from "../../assets/images/icons/safari-share.svg";
import androidMenu from "../../assets/images/icons/android-menu.svg";
import androidInstall from "../../assets/images/icons/android-install.svg";
import close from "../../assets/images/icons/close-icon.svg";
import './styles.scss';

const InstallSlideUp = ({ open, onClose }) => {

  const isAndroid = /android/i.test(window.navigator.userAgent);
  // const [isAndroid, setIsAndroid] = useState(/android/i.test(window.navigator.userAgent));


  return (
    <div className={`fixed bottom-0 left-0 right-0 flex flex-col items-center rounded-t-3xl bg-white p-5 text-sm font-semibold text-black ${open ? 'translate-y-0' : 'translate-y-100'}`}>
      <div className="mb-6 flex w-full items-center">
        <div className="flex-1">Install App</div>
        <img onClick={onClose} src={close} alt="" className="cursor-pointer" />
      </div>
      <div className="mb-6 flex w-full items-center gap-x-4 rounded-[10rem] p-5" >
        <div className="bg-mysteriousDepths rounded-[10rem] p-3" >
          <img className="w-6" src={icon} alt="" />
        </div>
        <div className="flex flex-col gap-y-1">
          <div>Mingo App</div>
          <div className="text-2xs text-dustyHeather">www.mingo.com</div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-3 px-4 text-xs">
        <div className="flex items-center font-normal">
          {isAndroid ? (
            <>
              <span className="mr-0.5 font-semibold">1.</span>
              Tap on <img className="mx-1 w-3" src={androidMenu} alt="" /> to open the menu.
            </>
          ) : (
            <>
              <span className="mr-0.5 font-semibold">1.</span>
              Tap on <img className="mx-1 w-3" src={safariShare} alt="" /> below, in Safari.
            </>
          )}
        </div>
        <div className="flex items-center font-normal">
          {isAndroid ? (
            <>
              <span className="mr-0.5 font-semibold">2.</span>
              Select&nbsp;
              <span className="bg-cerebralGrey it inline-flex rounded-[3rem] px-1 py-[2rem] font-semibold">
                <img className="mr-1 w-3" src={androidInstall} /> Install app
              </span>
            </>
          ) : (
            <>
              <span className="mr-1 font-semibold">2.</span>
              Scroll down and select <br />
              <span className="bg-grey rounded font-semibold" style={{ backgroundColor: 'grey'}} >
                Add to Home Screen
              </span>
            </>
          )}
        </div>
        <div className="flex font-normal">
          <span className="mr-1 font-semibold">3.</span>
          Look for the{' '} &nbsp;
          <span className="rounded px-1 flex items-cener" style={{ height: '40px', backgroundColor: '#104623'}}>
            <img className="" src={icon} alt="" /> &nbsp;
          </span>
          icon on your home screen.
        </div>
      </div>
    </div>
  );
};

export default InstallSlideUp;