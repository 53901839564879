import bgImg from '../../assets/images/background/Hero Section.png';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/itemslider.png';
import image from '../../assets/images/common/hero-image.png'
import { Button } from 'react-bootstrap';

const dataSlider = [
    {
        id: 1,
        superTitle: "THE WEB 3 TICKETING SOLUTION",
        title: <h1 className="title">Bringing <span className="special">WEB 3</span> to the Masses</h1>,
        desc: <p className="sub-title">MINGO is a full ecosystem developed entirely on the <span className='special'>Hedera Network</span>, consisting of MINGO Wallet, MINGO Market, MINGO Launch & MINGO Play.</p>,
        // desc : 'MINGO is a full ecosystem developed entirely on the Hedera Network, consisting of MINGO Wallet, MINGO Market, MINGO Launch & MINGO Play.',
        // bgImg: bgImg,
        img : image,
        button: true,
        
    },
    // {
    //     id: 2,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg2,
    //     classAction: 'two'
    // },
    // {
    //     id: 3,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg3,
    //     classAction: 'three'
    // },
    // {
    //     id: 4,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg,
    //     img : img1
    // },
    // {
    //     id: 5,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg,
    //     classAction: 'two'
    // },

]

export default dataSlider;