import React from 'react';
import Slider from '../components/slider';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/launch-hero.png';
import AboutMingo from '../features/about/ecosystem';
import AboutHedera from '../features/about/about-hedera';

//images
import image1 from '../assets/images/common/launch-about.png';
import Contact from './Contact';

const HeroSectionData = [
    {
        id: 1,
        superTitle: 'Mingo Ecosystem',
        title: <h1 className="title">MINGO <br /> LAUNCH</h1>,
        desc : <p className="sub-title">We collaborate with talented artists and creators to launch their NFT projects on the Hedera network. We also assist third-party game developers with Hedera integration, tokenomics, seed funding, and provide a launchpad for IGO events. Projects wishing to use the launchpad can apply by gaining community votes. Additionally, MINGO holders will be eligible for IGO event whitelists.</p>,
        img : image,
        button: false,
        btnText: 'Coming Soon',
        // p: 182
    },
]

const dataAbout = {
    title: 'THE TYSON FURY COLLECTION',
    superTitle: '14th February',
    desc: "16500 uniquely generated Tyson Fury NFTs living on the hedera network. There are a number of benefits for holders of this NFT collection, including access to a variety of utilities across the MINGO network, as well as the potential to win exclusive Tyson fan based experiences.",
    image: image1,
    button: true,
    btnText: 'Find Out More'
}

function MingoLaunch(props) {
    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />
            <AboutHedera data={dataAbout} reverse/>
            <Contact />
        </div>
    );
}

export default MingoLaunch;