const menus = [
    // {
    //     id: 1,
    //     name: 'Home',
    //     links: '/',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-v2'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-v3'
        //     },
        // ]
    // },
    {
        id:3,
        name: 'About',
        links: '/about'
    },
    {
        id: 2,
        name: 'EcoSystem',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'MINGO Wallet',
                links: '/wallet'
            },
            {
                id: 1,
                sub: 'MINGO Tickets',
                links: '/tickets'
            },
            // {
            //     id: 2,
            //     sub: 'MINGO Market',
            //     links: '/mingo-market'
            // },
            // {
            //     id: 3,
            //     sub: 'MINGO Launch',
            //     links: '/launch'
            // },
            {
                id: 4,
                sub: 'MINGO Play',
                links: '/play'
            },
            // {
            //     id: 5,
            //     sub: 'Team Details',
            //     links: '/team-details'
            // },
            // {
            //     id: 6,
            //     sub: 'FAQ',
            //     links: '/faq'
            // },
            // {
            //     id: 7,
            //     sub: '404',
            //     links: '/404'
            // },

        ]
    },
    // {
    //     id: 3,
    //     name: 'Road Map',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Road Map 01',
    //             links: '/road-map-v1'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Road Map 02',
    //             links: '/road-map-v2'
    //         },
    //     ]
    // },
    // {
    //     id: 4,
    //     name: 'Nft',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Nft Item',
    //             links: '/nft-item'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Nft Item Details',
    //             links: '/nft-item-details'
    //         },
    //     ],
    // },
    // {
    //     id: 5,
    //     name: 'Blog',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Our Blog',
    //             links: '/blog'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Details',
    //             links: '/blog-details'
    //         },
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Contact',
    //     links: '/contact'
    // },
    
]

export default menus;