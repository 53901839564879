import React from 'react';
import Slider from '../components/slider';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/market-hero.png';
import AboutMingo from '../features/about/ecosystem';
import AboutHedera from '../features/about/about-hedera';

//images
import image1 from '../assets/images/common/market-about.png';

const HeroSectionData = [
    {
        id: 1,
        superTitle: 'Mingo Ecosystem',
        title: <h1 className="title">MINGO MARKET</h1>,
        desc : <p className="sub-title">The marketplace is the go to for any purchases within the Mingo Ecosystem. Users can purchase NFTs directly using their credit card or by using MINGO and can list NFTs for sale on the secondary market..</p>,
        img : image,
        button: false,
        btnText: 'Coming Soon',
        p: 182
    },
]

const dataAbout = {
    title: 'Your Go-To Destination',
    superTitle: 'Mingo Marketplace',
    desc: "The marketplace is the go-to for any purchases within the Mingo Ecosystem. Users can purchase NFTs directly using their credit card or MINGO tokens. Additionally, they can list NFTs for sale on the secondary market, ensuring a dynamic and thriving trading environment.",
    image: image1,
}

function MingoMarket(props) {
    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />

            {/* <AboutMingo data={dataWork} /> */}
            <AboutHedera data={dataAbout} reverse/>
            {/* <AboutHedera data={dataAbout2}  /> */}
            {/* <Team data={dataTeam} /> */}

        </div>
    );
}

export default MingoMarket;