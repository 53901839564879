import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem({ item }) {


    return (
        <div className={`img-box ${item.id === 1 && 'img-box-special'}`}>
            <img src={item.img} alt="crybox" />
            <h5 className='title' to="/nft-item">{item.title}</h5>
            <p className='desc'>{item.desc}</p>
            {/* <div className="content">
            </div> */}
        </div>
    );
}

export default ProjectItem;