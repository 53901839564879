import React from 'react';
import Slider from '../components/slider';
import dataWork from '../assets/fake-data/data-work';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/about-hero.png';
import AboutMingo from '../features/about/ecosystem';
import AboutHedera from '../features/about/about-hedera';

//images
import image1 from '../assets/images/common/about-illustration.png';
const HeroSectionData = [
    {
        id: 1,
        superTitle: 'About Mingo',
        title: <h1 className="title">FUTURE OF DIGITAL INTERACTIONS</h1>,
        desc: <p>
            At MINGO, we're not just building an app; we're creating a comprehensive infrastructure that revolutionizes how individuals and businesses engage with the digital world. MINGO stands at the forefront of the <span className="special">Hedera Network</span>, delivering a suite of interconnected platforms that fuse entertainment, commerce, and finance into one seamless experience
        </p>,
        img : image,
        button: true
    },
]

const dataAbout = {
    title: 'Powered By Hedera',
    superTitle: 'Our Partner',
    desc: "A future built on trust. Hedera is the most used enterprise-grade public network for you to make your digital world exactly as it should be – yours. We're delighted to be working with Hedera to bring a platform built just for you.",
    button: true,
    image: image1,
}

const dataAbout2 = {
    title: 'The story behind MingoApp',
    superTitle: 'Our Story',
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.Lorem ipsum dolor sit amet consectetur adipiscing id elit volutpat amet tortor nunc ridiculus felis venenatis ipsum dui eu augue pellentesque diam neque lorem posueremorbi duis sed ut integer purus at nibh.",
    button: false,
    image: image1,
}

function HomeThree(props) {
    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />

            <AboutMingo data={dataWork} />
            <AboutHedera data={dataAbout}/>
            {/* <AboutHedera data={dataAbout2} reverse /> */}
            <Team data={dataTeam} />

        </div>
    );
}

export default HomeThree;