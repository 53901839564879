import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import openIcon from "../../../../assets/images/icons/openicon.png";
import { Link } from 'react-router-dom';

AboutMingoItem.propTypes = {
  item: PropTypes.object,  
};

function AboutMingoItem(props) {

    const {item} = props;

    return (
        <div className={`mingobox-text st2`}>
            <img src={openIcon} className="number" />
            <Link to={item.link} className='icon cursor-pointer'>
                <img src={item.icon} alt={item.title} className="icon"/>
            </Link>
            <div className="h7">{item.title}</div>
            <p>{item.text}</p>
        </div>
    );
}

export default AboutMingoItem;