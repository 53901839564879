import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AboutMingoItem from './aboutMingoItem';
import './aboutMingoItem/styles.scss';
import mingoPlay from "../../../assets/images/common/about-hedera.png";

AboutMingo.propTypes = {
    data: PropTypes.array,
};

const item = {
    id: 1,
    numb: '01',
    icon: mingoPlay,
    title: 'Mingo Wallet',
    text : 'Store Hedera’s native cryptocurrency, HBAR, along with all your Hedera HTS tokens, including MINGO, all in one secure place.',
    active: 'active',
    image: ''
};
function AboutMingo(props) {

    const {data} = props;

    const [dataBlock] = useState({
        subtitle: 'How we work',
        title: 'INTEGRATING YOUR DIGITAL LIFESTYLE!',
        desc: "MINGO is a unified ecosystem designed to enhance how you interact with digital assets across various aspects of your life. Through our integrated platforms on the Hedera network, we're simplifying the complexity of blockchain for everyday use."
    })
    return (
        <section className="tf-section mingo-about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title m-b17">{dataBlock.title}</h4>
                            <p className="">{dataBlock.desc} </p>
                        </div>
                    
                    </div>
                    <div className=" mingobox-text st2 col-md-12 align-items-center justify-space-around d-flex " data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                    <div >
                        {/* <img src={openIcon} className="number"/> */}
                        {/* <img src={item.icon} alt={item.title} className="icon"/> */}
                        <div className="h7">{item.title}</div>
                        <p>{item.text}</p>
                        <a href="http://"><span className="special">{`View More ->`}</span></a>
                    </div>
                    <div className="image flex-grow-1">
                        <img src={item.icon} alt={item.title} style={{width:"70vw",height:'auto'}} />
                    </div>
                    </div>
                    {
                        data.map(item => (
                            <div key={item.id} className="col-md-6 p-0" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <AboutMingoItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default AboutMingo;