import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ProjectItem from '../project-item';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;

    const [dataBlock] = useState({
        sutitle: 'NFT featured',
        title: 'Arts & collectibles'
    })
    return (
        <section className="tf-section tf-project pb-mobie home-2 ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 title-section">
                        <div className="tf-title st2 m-b50" data-aos="fade-up" data-aos-duration="800">
                            {/* <p className="h8 sub-title">{dataBlock.sutitle}</p> */}
                            <h4 className="title m-b17">All-in-one crypto: buy, trade, invest. <br />
Powered by Hedera.</h4>
                        </div>
                        <div >
                            <button className="tf-button-st2 btn-effect"><span className='effect'>Download App</span></button>
                        </div>
                    </div>

                    {
                        data.slice(0,5).map((item,i) => (
                            <div key={item.id} className={`col-xl-4 col-md-4 p-0 col-12 ${item.id === 1 && 'col-xl-8'} `}>
                                <ProjectItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Project;