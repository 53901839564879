import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OfferItem from './offer-item';
import './styles.scss';

Offers.propTypes = {
    data: PropTypes.array,
};

function Offers({ data }) {

    const [dataBlock] = useState({
        sutitle: 'NFT featured',
        title: 'Arts & collectibles'
    })
    return (
        <section  className="tf-section tf-offer pb-mobie home-2 ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 m-b50" data-aos="fade-up" data-aos-duration="800">
                            {/* <p className="h8 sub-title">{dataBlock.sutitle}</p> */}
                            <h4 className="title m-b17">Features <span className='special'>MINGO</span> Offers!</h4>
                            <p>MINGO is one of the first tokens to go live on the Hedera network. It is what powers the entire Mingo network and has a wide variety of utility throughout the ecosystem.</p>
                        </div>
                    </div>

                    {
                        data.slice(0,5).map((item,i) => (
                            <div key={item.id} className={`col-xl-4 col-md-4 col-12 ${item.id == 4 && 'col-xl-8'}`}>
                                <OfferItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Offers;