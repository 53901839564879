import mingoPlay from "../images/icons/mingoplay.png";
import mingoWallet from "../images/icons/mingowallet.png";
import mingoLaunch from "../images/icons/mingolaunch.png";
import mingoChat from "../images/icons/mingochat.png";

const dataWork = [
    {
        id: 1,
        numb: '01',
        icon: mingoWallet,
        title: 'Mingo Ticket',
        text : 'Mingo Tickets transforms event ticketing with blockchain, ensuring a transparent and fraud-free experience. Our platform streamlines ticket purchases and sales, making every event entry smooth and secure.',
        active: 'active',
        link: '/tickets'
    },
    {
        id: 2,
        numb: '02',
        icon: mingoPlay,
        title: 'Mingo Play',
        text : 'MingoPlay is at the forefront of blockchain gaming, developing engaging play-to-earn games that leverage decentralized technologies to reward players for their participation and skill.',
        link: '/play'
    },
    // {
    //     id: 3,
    //     numb: '03',
    //     icon: mingoLaunch,
    //     title: "Mingo Launch",
    //     text : '"MingoLaunch, on the Hedera network, is a specialized platform for launching NFT projects, providing creators a streamlined pathway to showcase their digital artworks and assets.'
    // },
    // {
    //     id: 4,
    //     numb: '04',
    //     icon: mingoChat,
    //     title: "Mingo Chat",
    //     text : 'Consolidate all your messages from various supported platforms into a single, user-friendly app. Experience seamless communication without switching between different applications.'
    // },
]

export const mingoTickets = [
    {
        id: 1,
        numb: '01',
        icon: mingoPlay,
        title: 'Smart Programmable Tickets',
        text : 'Gain complete control and insight throughout the duration of your event, enhanced by real-time data that tracks ticket ownership and activity.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        icon: mingoWallet,
        title: 'Eliminating Ticket Fraud',
        text : 'Each ticket is securely registered on a distributed ledger and linked to the attendee’s smartphone, rendering fraud virtually impossible.'
    },
    {
        id: 3,
        numb: '03',
        icon: mingoLaunch,
        title: "Create, Validate, Manage",
        text : 'MINGO Tickets not only facilitates the creation and validation of various ticket types, while also overseeing a secure and regulated resale environment.'
    },
    {
        id: 4,
        numb: '04',
        icon: mingoChat,
        title: "Primary & Secondary Markets",
        text : 'Generate and sell tickets for multiple channels in the primary market, and enable buyers to resell them within a regulated secondary market'
    },
]
export default dataWork;