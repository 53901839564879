import React from 'react';
import Slider from '../components/slider';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import image from '../assets/images/common/play-hero.png';
import mingoPlayImg from '../assets/images/post/moonKart_conceptBanner.png';
import AboutHedera from '../features/about/about-hedera';
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
//images
import image1 from '../assets/images/common/game-play.png';
import gameLogo from '../assets/images/post/astro-karts-logo.png';
import { Link } from 'react-router-dom';

const HeroSectionData = [
    {
        id: 1,
        superTitle: 'Welcome To',
        title: <h1 className="title">MINGO PLAY</h1>,
        desc : <p className="sub-title">Enjoy casual gaming with MINGO Play! Our games are designed for quick sessions to fit any schedule. Play directly in your browser on any device. Whether you have five minutes or an hour, there’s always time for fun</p>,
        img : image,
        button: false,
        btnText: 'Find Out More',
        p: 182
    },
]

const gameData = [
    {
        id: 1,
        title: 'Dynamic Combat Racing',
        desc: "Outsmart and outlast competitors in high-stakes matches where strategy is just as important as speed",
    },
    {
        id: 2,
        title: 'Endless Customization',
        desc: "Personalize your karts and characters with an array of skins and performance parts, crafting your unique style of chaos",
    },
    {
        id: 3,
        title: 'Earn While You Play',
        desc: "Compete in PvP modes where every race could earn you valuable Mingo coins. Utilize your winnings to enhance your gaming experience or cash out — the choice is yours!",
    },
    {
        id: 4,
        title: 'Powered by Blockchain',
        desc: 'Built on the Hedera network, every in-game item from karts to gear can be owned, traded, and sold as NFTs, providing real-world value'
    }
]

const dataAbout = {
    title: 'QUICK & CASUAL ON THE HEDERA NETWORK',
    superTitle: 'Mingo Ecosystem',
    desc: "Mingo Play offers a suite of casual games designed to be fun, fast, and fair. Powered by the Hedera network, MINGO guarantees lightning-fast transactions and robust security, ensuring a smooth and enjoyable gaming experience for everyone. With Mingo Play, games are not just about earning; they're about enjoying every moment. Dive into the Mingoverse, suitable for all types of players and devices. Whether you're in for a quick flick or a long session, we’re bringing you a seamless gaming experience, anytime, anywhere.",
    image: image1,
    button: false,
}

function MingoPlay(props) {
    return (
        <div className='home-3'>
            <Slider data={HeroSectionData} />
            <AboutHedera data={dataAbout} reverse/>
            <section className="tf-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title  t-left" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">Upcoming Game Release</p>
                            <h4 className="title">AstroKarts: Thrills Meet Strategy in the Cosmos</h4>
                            <p className="">Get ready for AstroKarts, an adrenaline-pumping battle-royale karting game set in expansive, ever-changing cosmic arenas. Designed for thrill-seekers who crave intense competition, AstroKarts redefines racing with its dynamic battle tactics and endless excitement.</p>
                        </div>
                    </div>
                    <div className="col-md-12  image-mask">
                        <img src={mingoPlayImg} alt="Meta Fighting championship" />
                        {/* image overlay */}
                        <div className="tf-overlay">
                        <img src={gameLogo} alt="AstroKarts" width={300}/>
                           
                            <Swiper
                                modules={[Navigation,  Scrollbar, A11y ]}
                                spaceBetween={10}
                                autoplay={{ delay: 2000 }}
                                slidesPerView={1}
                                scrollbar={{ draggable: true }}
                                style={{
                                    width: '70%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                                >
                                {
                                    gameData.map(item => (
                                        <SwiperSlide key={item.id}>
                                            <div className="tf-title-st2 mb-60 d-none d-sm-block">
                                                <h5 className="title m-b17 d-none d-sm-block">{item.title}</h5>
                                                <p className="">{item.desc} </p>
                                            </div>
                                            {/* <SliderItem item={item} /> */}
                                        </SwiperSlide>
                                        
                                    ))
                                }
                            </Swiper>
                            {/* open n iframe http://astrokarts.com upon clicking */}
                            <Link to="/" className="tf-button-st2 btn-effect overlay-btn"><span className="effect"
                            onClick={() => window.open('http://astrokarts.com')}
                            >
                                Play Now <i className="fal fa-arrow-down"></i></span></Link>
                        </div>

                    </div>
                    </div>
            </div>
        </section>
        </div>
    );
}

export default MingoPlay;