import React, { useState } from 'react';
import InstallOnlyOnMobile from '../components/install/InstallOnlyOnMobile';
// import AppButton from '@mingo/mingo-ui/components/AppButton.vue';
import mingoIcon from '../assets/images/icons/icon.svg';
import isMobile from 'is-mobile';
import appPreview1 from '../assets/images/icons/app-preview-1.jpeg';
import appPreview2 from '../assets/images/icons/app-preview-2.jpeg';
import InstallSlideUp from '../components/install/InstallSlideUp';
// import './Install.scss';

const Install = () => {
  const [installSlideUpOpen, setInstallSlideUpOpen] = useState(false);
  const mobile = isMobile();

  const openInstallApp = () => {
    setInstallSlideUpOpen(true);
  };

  console.log("ismobile", mobile);

  return (
    <div className={`container`}>
    <div className={`container ${!mobile && 'd-none'} mt-5`}>
      <div className=" row justify-content-center align-items-center min-vh-100">
        <div className="col-md-8 text-center ">
          <img src={mingoIcon} alt="" className="mb-3" />
          <h6 className="fw-bold">Mingo App</h6>
          <br />
          <br />
          <button className="tf-button btn-effect" onClick={() => openInstallApp()}>        
            <span className="boder-fade"></span>                                     
            <span className="effect">Install</span>
        </button>
          <div className="row justify-content-center mt-5">
            <div className="col-md-6">
              <img src={appPreview1} alt="" className="img-fluid mb-3" />
            </div>
            <div className="col-md-6">
              <img src={appPreview2} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="mt-5">
            <h6 className="fw-bold">About Mingo App</h6>
            <p className="text-muted">
              Mingo Technologies was built to improve lives by making distributed ledger technology meaningfully accessible.
            </p>
          </div>
          <InstallSlideUp open={installSlideUpOpen} onClose={() => setInstallSlideUpOpen(false)} />
        </div>
      </div>
    </div>
      {!mobile && <InstallOnlyOnMobile />}
    </div>
  );
};

export default Install;
